<template>
  <div class="px-5 py-5 elevation-6 rounded">
    <div class="d-flex justify-space-between">
      <div class="d-flex action">
        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="(vue = 'voir'), getInscription(), init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-clipboard-text</v-icon>
          <span class="text-caption text-uppercase">Voir</span>
        </v-sheet>

        <v-sheet
          color="primary"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="(vue = 'creer'), init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-pencil</v-icon>
          <span class="text-caption text-uppercase">Créer</span>
        </v-sheet>
      </div>
      <div class="d-flex save">
        <v-sheet
          :color="trash.save ? 'red' : 'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="supprimer()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-delete</v-icon>
          <span class="text-caption text-uppercase">Supprimer</span>
        </v-sheet>

        <v-sheet
          :color="create.save || update.save ? 'indigo' : 'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="save()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-content-save</v-icon>
          <span class="text-caption text-uppercase">Garder</span>
        </v-sheet>
      </div>
    </div>
    <div v-if="vue == 'voir'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">ID</th>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Année</th>
            <th class="primary--text">Début</th>
            <th class="primary--text">Fin</th>
            <th class="primary--text">Activer</th>
            <th class="primary--text">Select</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in arrayInscription"
            :key="index"
            @dblclick="
              (vue = 'modifier'), assignUpdate(item), (itemUpdate = item)
            "
          >
            <!-- <tr v-for="(item,index) in arrayInscription" :key="index" @dblclick="vue='modifier',assignUpdate(item),itemUpdate = item,update.switchCreateActivate = item.actif, update.ref = item.ref"> -->
            <td>{{ index + 1 }}</td>
            <td>{{ item.saison }}</td>
            <td>{{ item.yearInscription }}</td>
            <td>{{ $moment(item.startDate) }}</td>
            <td>{{ $moment(item.endDate) }}</td>
            <td>
              <v-switch
                readonly
                v-model="item.actif"
                hide-details
                inset
                class="mt-0 pt-0"
              ></v-switch>
            </td>
            <td>
              <v-checkbox
                :key="item.ref + index"
                v-model="selected"
                :value="item.ref"
                @change="controlSelect()"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'creer'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Année</th>
            <th class="primary--text">Début</th>
            <th class="primary--text">Fin</th>
            <th class="primary--text">Activer</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="d-inline-block mx-0" max-width="75">
                <v-text-field
                  v-model="create.saisonStart"
                  @change="controlSave()"
                  @keyup="controlSave()"
                  placeholder="2000"
                ></v-text-field>
              </v-responsive>
              <v-responsive class="d-inline-block mx-4" max-width="10"
                ><v-text-field
                  class="border-0"
                  placeholder="/"
                  readonly
                ></v-text-field
              ></v-responsive>
              <v-responsive class="d-inline-block mx-0" max-width="75">
                <v-text-field
                  v-model="create.saisonEnd"
                  @change="controlSave()"
                  @keyup="controlSave()"
                  placeholder="2001"
                ></v-text-field>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="75">
                <v-text-field
                  v-model="create.yearInscription"
                  @change="controlSave()"
                  @keyup="controlSave()"
                  placeholder="2001"
                ></v-text-field>
              </v-responsive>
            </td>
            <td>
              <!-- <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-responsive
                      class="mx-0"
                      max-width="85"
                    >
                      <v-text-field
                        v-model="selectedDateStart"
                        v-on="on"
                        value
                        label="Date"
                        color="green lighten-1"
                      />
                    </v-responsive>
                  </template>
                  <v-date-picker
                    v-model="selectedDateStart"
                    @input="menu1 = false"
                    class="my-0"
                    header-color="green lighten-1"
                    color="green lighten-1"
                  />
                </v-menu> -->
              <v-dialog
                ref="dialog"
                v-model="create.modal"
                :return-value.sync="create.date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0" max-width="115">
                    <v-text-field
                      v-model="create.date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-date-picker
                  v-model="create.date"
                  locale="fr"
                  color="primary"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="create.modal = false"
                    >Annuler</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(create.date), controlSave()"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </td>
            <td>
              <v-dialog
                ref="dialog2"
                v-model="create.modal2"
                :return-value.sync="create.date2"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0" max-width="115">
                    <v-text-field
                      v-model="create.date2"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-date-picker
                  v-model="create.date2"
                  locale="fr"
                  color="primary"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="create.modal2 = false"
                    >Annuler</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog2.save(create.date2), controlSave()"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </td>
            <td>
              <v-switch
                v-model="create.switchCreateActivate"
                @change="controlSave()"
                hide-details
                inset
                class="mt-0 pt-0"
              ></v-switch>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'modifier'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Année</th>
            <th class="primary--text">Début</th>
            <th class="primary--text">Fin</th>
            <th class="primary--text">Activer</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="d-inline-block mx-0" max-width="75">
                <v-text-field
                  v-model="update.saisonStart"
                  readonly
                  :placeholder="''"
                  @change="controlSave()"
                  @keyup="controlSave()"
                ></v-text-field>
                <!-- <v-text-field v-model="update.saisonStart" readonly :placeholder="itemUpdate.saison.split('/')[0]" @change="controlSave(itemUpdate)" @keyup="controlSave(itemUpdate)"></v-text-field> -->
              </v-responsive>
              <v-responsive class="d-inline-block mx-4" max-width="10"
                ><v-text-field
                  class="border-0"
                  placeholder="/"
                  readonly
                ></v-text-field
              ></v-responsive>
              <v-responsive class="d-inline-block mx-0" max-width="75">
                <v-text-field
                  v-model="update.saisonEnd"
                  readonly
                  :placeholder="''"
                  @change="controlSave()"
                  @keyup="controlSave()"
                ></v-text-field>
                <!-- <v-text-field v-model="update.saisonEnd" readonly :placeholder="itemUpdate.saison.split('/')[1]" @change="controlSave(itemUpdate)" @keyup="controlSave(itemUpdate)"></v-text-field> -->
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="75">
                <v-text-field
                  v-model="update.yearInscription"
                  readonly
                  :placeholder="''"
                  @change="controlSave()"
                  @keyup="controlSave()"
                ></v-text-field>
                <!-- <v-text-field v-model="update.yearInscription" readonly :placeholder="itemUpdate.yearInscription" @change="controlSave(itemUpdate)" @keyup="controlSave(itemUpdate)"></v-text-field> -->
              </v-responsive>
            </td>
            <td>
              <v-dialog
                ref="dialog"
                v-model="update.modal"
                :return-value.sync="update.date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0" max-width="145">
                    <v-text-field
                      v-model="update.date"
                      :placeholder="'Date'"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="(update.date = null), controlSave()"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-date-picker
                  v-model="update.date"
                  locale="fr"
                  color="primary"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="update.modal = false"
                    >Annuler</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(update.date), controlSave()"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </td>
            <td>
              <v-dialog
                ref="dialog2"
                v-model="update.modal2"
                :return-value.sync="update.date2"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0" max-width="145">
                    <v-text-field
                      v-model="update.date2"
                      :placeholder="'Date'"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="(update.date2 = null), controlSave()"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-date-picker
                  v-model="update.date2"
                  locale="fr"
                  color="primary"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="update.modal2 = false"
                    >Annuler</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog2.save(update.date2), controlSave()"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </td>
            <td>
              <v-switch
                v-model="update.switchCreateActivate"
                @change="controlSave()"
                hide-details
                inset
                class="mt-0 pt-0"
              ></v-switch>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Inscriptions from "@/services/Inscription.js";
export default {
  name: "Inscriptions",
  data: () => ({
    // switchActif: false,
    itemUpdate: {},

    selected: [],
    trash: {
      save: false,
    },

    update: {
      switchCreateActivate: false,
      yearInscription: "",
      saisonEnd: "",
      saisonStart: "",
      save: false,
      date: null,
      date2: null,
      ref: null,

      modal: false,
      modal2: false,
    },

    create: {
      switchCreateActivate: false,
      yearInscription: "",
      saisonEnd: "",
      saisonStart: "",
      save: false,
      date: null,
      date2: null,

      modal: false,
      modal2: false,
    },
    // menu1:null,
    // date:null,
    // selectedDateStart:"",
    arrayInscription: [],
    vue: "voir",
  }),
  mounted() {
    this.getInscription();
  },
  methods: {
    init() {
      Object.assign(this.$data.create, this.$options.data().create);
      Object.assign(this.$data.update, this.$options.data().update);
      Object.assign(this.$data.trash, this.$options.data().trash);
      this.itemUpdate = {};
      this.selected = [];
    },
    assignUpdate(item) {
      this.update.switchCreateActivate = item.actif;
      this.update.yearInscription = item.yearInscription;
      this.update.saisonEnd = item.saison.split("/")[1];
      this.update.saisonStart = item.saison.split("/")[0];
      this.update.date = item.startDate.substr(0, 10);
      this.update.date2 = item.endDate.substr(0, 10);
      this.update.ref = item.ref;
    },

    controlSelect() {
      if (this.selected.length && this.vue == "voir") {
        this.trash.save = true;
      } else {
        this.trash.save = false;
      }
    },

    async supprimer() {
      // console.log("this.selected", this.selected);
      if (this.trash.save) {
        let deleteInscription = await Inscriptions.delete({
          array_ref_inscription: this.selected,
        });
        // console.log("deleteInscription save", deleteInscription);
        this.trash.save = !deleteInscription;
        this.selected = [];
        this.getInscription();
      }
    },

    async save() {
      // console.log("save 1");
      if (this.vue == "modifier" && this.update.save) {
        // console.log("save 1");
        let putInscription = await Inscriptions.put({
          ref: this.update.ref,
          actif: this.update.switchCreateActivate,
          startDate: this.update.date,
          endDate: this.update.date2,
        });
        // console.log("putInscription save", putInscription);
        this.update.save = !putInscription;
        // if(putInscription){
        // this.update.date=null
        // this.update.date2=null
        // this.update.switchCreateActivate=null
        // }
      }

      if (this.vue == "creer" && this.create.save) {
        // console.log("save 2");
        let postInscription = await Inscriptions.post({
          saison: this.create.saisonStart + "/" + this.create.saisonEnd,
          yearInscription: this.create.yearInscription,
          actif: this.create.switchCreateActivate,
          startDate: this.create.date,
          endDate: this.create.date2,
        });
        // this.create.save = !postInscription
        if (postInscription) {
          Object.assign(this.$data.create, this.$options.data().create);
          // this.create.saisonStart=""
          // this.create.saisonEnd=""
          // this.create.yearInscription=""
          // this.create.date=null
          // this.create.date2=null
          // this.create.switchCreateActivate=null
        }
      }
    },
    controlSave() {
      // console.log("test");
      if (
        this.vue == "creer" &&
        this.create.saisonStart != "" &&
        this.create.saisonEnd != "" &&
        this.create.yearInscription != "" &&
        this.create.date != null &&
        this.create.date2 != null
        // && (this.create.switchCreateActivate!=null)
      ) {
        // console.log("this.saisonStart 1", this.create);
        this.create.save = true;
      } else {
        // console.log("this.saisonStart 2", this.create.saisonStart);
        this.create.save = false;
      }

      if (
        (this.vue == "modifier" &&
          this.$moment(this.update.date) !=
            this.$moment(this.itemUpdate.startDate) &&
          this.update.date != null &&
          this.itemUpdate.startDate != undefined) ||
        (this.$moment(this.update.date2) !=
          this.$moment(this.itemUpdate.endDate) &&
          this.update.date2 != null &&
          this.itemUpdate.endDate != undefined) ||
        (this.update.switchCreateActivate != this.itemUpdate.actif &&
          this.itemUpdate.actif != undefined)
      ) {
        // console.log("save update 1",item.startDate)
        // console.log("this.update.date", this.update.date);
        // console.log("this.update itemDate", this.itemUpdate.startDate);
        // console.log("this.itemDate", this.itemUpdate);
        // console.log("this.update item", this.$moment(this.itemUpdate.endDate));
        // console.log("this.update", this.$moment(this.update.date2));
        this.update.save = true;
      } else {
        // console.log("save update 2", this.update);
        // console.log("save item update 2", this.itemUpdate.actif);
        this.update.save = false;
      }
    },
    $moment(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getInscription() {
      this.arrayInscription = await Inscriptions.get();
    },
  },
};
</script>
