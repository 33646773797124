<template>
  <div class="px-5 py-5 elevation-6 rounded">
    <div class="d-flex justify-space-between">
      <div class="d-flex action">
        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="(vue = 'voir'), init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-clipboard-text</v-icon>
          <span class="text-caption text-uppercase">Voir</span>
        </v-sheet>

        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="(vue = 'creer'), getSaison(), init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-pencil</v-icon>
          <span class="text-caption text-uppercase">Créer</span>
        </v-sheet>
      </div>
      <div class="d-flex save">
        <v-sheet
          :color="trash.save ? 'red' : 'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="supprimer()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-delete</v-icon>
          <span class="text-caption text-uppercase">Supprimer</span>
        </v-sheet>

        <v-sheet
          :color="create.save || update.save || add.save ? 'indigo' : 'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="save()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-content-save</v-icon>
          <span class="text-caption text-uppercase">Garder</span>
        </v-sheet>
      </div>
    </div>
    <div v-if="vue == 'voir'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlVoir()"
                  v-model="voir.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlVoir()"
                  v-model="voir.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Tous', value: null },
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table v-if="horaire.length">
        <thead>
          <tr>
            <th class="primary--text">ID</th>
            <th class="primary--text">Catégorie</th>
            <th class="primary--text">localisation</th>
            <th class="primary--text">Créneau(x)</th>
            <th class="primary--text">Select</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in horaire"
            :key="index"
            @dblclick="
              (vue = 'modifier'),
                assignUpdate(item),
                (itemUpdate = item),
                (selected = []),
                (trash.save = false)
            "
          >
            <td>
              {{ index + 1 }}
            </td>
            <td>
              {{ capitalization(item.categorie) }}
            </td>
            <td>
              {{ item.location }}
            </td>
            <td>{{ item.creneau.length }}</td>
            <td>
              <v-checkbox
                :key="item.ref + index"
                v-model="selected"
                :value="item.ref"
                @change="controlSelect()"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'creer'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
            <th class="primary--text">Categorie</th>
            <th class="primary--text">Localisation</th>
            <th class="primary--text">Lecture simple</th>
            <!-- <th class="primary--text">Age entre</th> -->
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  v-model="create.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  v-model="create.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="250">
                <v-select
                  @change="controlSave()"
                  v-model="create.categorie"
                  :placeholder="'Catégorie'"
                  :items="categorie"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="create.localisation"
                  :items="salle"
                  :placeholder="'Salle'"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-switch
                @change="controlSave()"
                class="mt-0 pt-0"
                v-model="create.switchReadOnly"
              ></v-switch>
            </td>
            <!-- <td>
                <v-responsive
                  class="ml-0 mr-4 d-inline-block"
                  max-width="70"
                >
                  <v-text-field :placeholder="'min'"></v-text-field>
                </v-responsive>
                <v-responsive
                  class="mx-0 d-inline-block"
                  max-width="70"
                >
                  <v-text-field :placeholder="'max'"></v-text-field>
                </v-responsive>
              </td> -->
          </tr>
        </tbody>
      </v-simple-table>
      <!-- <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Description</th>
              <th class="primary--text">Localisation</th>
              <th class="primary--text">Adresse</th>
            </tr>
          </thead>

          <tbody>
            <tr>            
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="200"
                >
                  <v-textarea @change="controlSave()" @keyup="controlSave()" rows="1" v-model="create.description" :placeholder="'Description'"></v-textarea>                
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="create.localisation" :placeholder="'Localisation'"></v-text-field>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="250"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="create.adresse" :placeholder="'Adresse'"></v-text-field>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table> -->
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Jour</th>
            <th class="primary--text">Creneau</th>
            <th class="primary--text">Description</th>
            <th class="primary--text">Quota</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in create.creneau" :key="index">
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="item.jour"
                  :placeholder="'Jour'"
                  :items="[
                    'Lundi',
                    'Mardi',
                    'Mercredi',
                    'Jeudi',
                    'Vendredi',
                    'Samedi',
                    'Dimanche',
                  ]"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-dialog
                :ref="'dialog' + index"
                v-model="item.modal"
                :return-value.sync="item.time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0 d-inline-block" max-width="150">
                    <v-text-field
                      v-model="item.time"
                      label="Heure début"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-time-picker
                  v-if="item.modal"
                  v-model="item.time"
                  full-width
                  format="24hr"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="item.modal = false">
                    Annuler
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs['dialog' + index][0].save(item.time), controlSave()
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
              <v-dialog
                :ref="'dialog2' + index"
                v-model="item.modal2"
                :return-value.sync="item.time2"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0 d-inline-block" max-width="150">
                    <v-text-field
                      v-model="item.time2"
                      label="Heure fin"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-time-picker
                  v-if="item.modal2"
                  v-model="item.time2"
                  full-width
                  format="24hr"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="item.modal2 = false">
                    Annuler
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs['dialog2' + index][0].save(item.time2),
                        controlSave()
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="200">
                <v-textarea
                  @change="controlSave()"
                  @keyup="controlSave()"
                  rows="1"
                  v-model="item.description2"
                  :placeholder="'Description'"
                ></v-textarea>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="item.quota"
                  :placeholder="'Quota'"
                ></v-text-field>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'modifier'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
            <th class="primary--text">Categorie</th>
            <th class="primary--text">Localisation</th>
            <th class="primary--text">Lecture simple</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  readonly
                  v-model="update.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  readonly
                  v-model="update.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="250">
                <v-select
                  @change="controlSave()"
                  v-model="update.categorie"
                  :placeholder="'Catégorie'"
                  :items="categorie"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="update.localisation"
                  :items="salle"
                  :placeholder="'Salle'"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-switch
                @change="controlSave()"
                class="mt-0 pt-0"
                v-model="update.switchReadOnly"
              ></v-switch>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <!-- <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">Description</th>
              <th class="primary--text">Localisation</th>
              <th class="primary--text">Adresse</th>
            </tr>
          </thead>

          <tbody>
            <tr>            
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="200"
                >
                  <v-textarea @change="controlSave()" @keyup="controlSave()" rows="1" v-model="update.description" :placeholder="'Description'"></v-textarea>                
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="150"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.localisation" :placeholder="'Localisation'"></v-text-field>
                </v-responsive>
              </td>
              <td>
                <v-responsive
                  class="mx-0"
                  max-width="250"
                >
                  <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.adresse" :placeholder="'Adresse'"></v-text-field>
                </v-responsive>
              </td>
            </tr>
          </tbody>
      </v-simple-table> -->
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Jour</th>
            <th class="primary--text">Creneau</th>
            <th class="primary--text">Description</th>
            <th class="primary--text">Quota</th>
            <th v-if="itemUpdate.creneau.length > 1" class="primary--text">
              Select
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in itemUpdate.creneau"
            :key="index"
            @dblclick="
              (vue = 'modifier-2'), assignUpdate(item), (itemUpdate = item)
            "
          >
            <td>{{ item.jour }}</td>
            <td>{{ item.creneau }}</td>
            <td>{{ item.description.substr(0, 300) }}</td>
            <td>{{ item.quotas > 0 ? item.quotas : "aucun" }}</td>
            <td v-if="itemUpdate.creneau.length > 1">
              <v-checkbox
                :key="item.ref + index"
                v-model="selected"
                :value="item.ref"
                @change="controlSelect()"
                :disabled="
                  itemUpdate.creneau.length - 1 == selected.length &&
                  !selected.includes(item.ref)
                    ? true
                    : false
                "
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-sheet
        color="primary "
        elevation="6"
        class="mr-5 d-flex text-start align-center justify-center flex-column pa-3 mt-4"
        dark
        :max-width="65"
        @click="vue = 'ajouter'"
        style="cursor: pointer"
      >
        <v-icon size="32">mdi-plus-box</v-icon>
        <span class="text-caption text-uppercase">Ajouter</span>
      </v-sheet>
    </div>
    <div v-if="vue == 'ajouter'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Jour</th>
            <th class="primary--text">Creneau</th>
            <th class="primary--text">Description</th>
            <th class="primary--text">Quota</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in add.creneau" :key="index">
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="item.jour"
                  :placeholder="'Jour'"
                  :items="[
                    'Lundi',
                    'Mardi',
                    'Mercredi',
                    'Jeudi',
                    'Vendredi',
                    'Samedi',
                    'Dimanche',
                  ]"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-dialog
                :ref="'dialog' + index"
                v-model="item.modal"
                :return-value.sync="item.time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0 d-inline-block" max-width="150">
                    <v-text-field
                      v-model="item.time"
                      label="Heure début"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-time-picker
                  v-if="item.modal"
                  v-model="item.time"
                  full-width
                  format="24hr"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="item.modal = false">
                    Annuler
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs['dialog' + index][0].save(item.time), controlSave()
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
              <v-dialog
                :ref="'dialog2' + index"
                v-model="item.modal2"
                :return-value.sync="item.time2"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0 d-inline-block" max-width="150">
                    <v-text-field
                      v-model="item.time2"
                      label="Heure fin"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-time-picker
                  v-if="item.modal2"
                  v-model="item.time2"
                  full-width
                  format="24hr"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="item.modal2 = false">
                    Annuler
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs['dialog2' + index][0].save(item.time2),
                        controlSave()
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="200">
                <v-textarea
                  @change="controlSave()"
                  @keyup="controlSave()"
                  rows="1"
                  v-model="item.description2"
                  :placeholder="'Description'"
                ></v-textarea>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="item.quota"
                  :placeholder="'Quota'"
                ></v-text-field>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'modifier-2'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Jour</th>
            <th class="primary--text">Creneau</th>
            <th class="primary--text">Description</th>
            <th class="primary--text">Quota</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in update.creneau" :key="index">
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="item.jour"
                  :placeholder="'Jour'"
                  :items="[
                    'Lundi',
                    'Mardi',
                    'Mercredi',
                    'Jeudi',
                    'Vendredi',
                    'Samedi',
                    'Dimanche',
                  ]"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-dialog
                :ref="'dialog' + index"
                v-model="item.modal"
                :return-value.sync="item.time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0 d-inline-block" max-width="150">
                    <v-text-field
                      v-model="item.time"
                      label="Heure début"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-time-picker
                  v-if="item.modal"
                  v-model="item.time"
                  full-width
                  format="24hr"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="item.modal = false">
                    Annuler
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs['dialog' + index][0].save(item.time), controlSave()
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
              <v-dialog
                :ref="'dialog2' + index"
                v-model="item.modal2"
                :return-value.sync="item.time2"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-responsive class="mx-0 d-inline-block" max-width="150">
                    <v-text-field
                      v-model="item.time2"
                      label="Heure fin"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-responsive>
                </template>
                <v-time-picker
                  v-if="item.modal2"
                  v-model="item.time2"
                  full-width
                  format="24hr"
                  color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="item.modal2 = false">
                    Annuler
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs['dialog2' + index][0].save(item.time2),
                        controlSave()
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="200">
                <v-textarea
                  @change="controlSave()"
                  @keyup="controlSave()"
                  rows="1"
                  v-model="item.description2"
                  :placeholder="'Description'"
                ></v-textarea>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="item.quota"
                  :placeholder="'Quota'"
                ></v-text-field>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Inscriptions from "@/services/Inscription.js";
import Categories from "@/services/Categorie.js";
import Horaires from "@/services/Horaire.js";
import Horaire_days from "@/services/Horaire_day.js";
import Salles from "@/services/Salle.js";
export default {
  name: "Inscriptions",
  data: () => ({
    // Horaire
    saison: [],
    categorie: [],
    salle: [],
    arraySalle: [],
    horaire: [],
    // horaireFilter:[],
    arrayInscription: [],
    // Horaire

    selected: [],
    trash: {
      save: false,
    },

    itemUpdate: {},

    add: {
      save: false,
      ref: null,
      creneau: [
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
      ],
    },

    update: {
      save: false,
      ref: null,
      saison: "",
      type: null,
      categorie: "",
      description: "",
      localisation: "",
      adresse: "",
      switchReadOnly: false,

      creneau: [
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
      ],
    },

    voir: {
      saison: "",
      type: null,
    },

    create: {
      save: false,
      ref: null,
      saison: "",
      type: null,
      categorie: "",
      // description:"",
      localisation: "",
      // adresse:"",
      switchReadOnly: false,

      creneau: [
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
        {
          jour: "",
          time2: null,
          time: null,
          description2: "",
          quota: "",
          modal2: false,
          modal: false,
        },
      ],
    },
    arrayInscription: [],
    vue: "voir",
  }),
  mounted() {
    this.getSaison();
  },
  watch: {
    // 'voir.type': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue type voir",newValue)
    //     if(newValue!="" && this.voir.saison!=""){
    //       // let options = {ref:this.voir.saison}
    //       // this.getHoraire(options)
    //       this.horaire = this.horaireFilter.filter(element=>element.type == newValue)
    //     }
    //   },
    //   deep: true
    // },
    // 'voir.saison': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue saison voir",newValue)
    //     if(newValue!=""){
    //       this.voir.type =null
    //       let options = {ref:newValue}
    //       this.getHoraire(options)
    //     }
    //   },
    //   deep: true
    // },

    // 'update.saison': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue saison",newValue)
    //     if(newValue!="" && this.update.type != null){
    //       // this.update.categorie = ""
    //       let options = {type:this.update.type,saison:newValue}
    //       this.getCategorieByTypeInscription(options)
    //     }
    //     if(newValue!=""){
    //       // this.update.localisation = ""
    //       let options = {ref_inscription:newValue}
    //       this.getSalleByInscription(options)
    //     }
    //   },
    //   deep: true
    // },
    // 'update.type': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue type",newValue)
    //     if(newValue!="" && this.update.saison != ""){
    //       // this.update.categorie = ""
    //       let options = {saison:this.update.saison,type:newValue}
    //       this.getCategorieByTypeInscription(options)
    //     }
    //   },
    //   deep: true
    // },

    // 'create.saison': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue saison",newValue)
    //     if(newValue!="" && this.create.type != null){
    //       this.create.categorie = ""
    //       let options = {type:this.create.type,saison:newValue,ref_inscription:newValue}
    //       this.getCategorieByTypeInscription(options)
    //       this.getSalle(options)
    //     }
    //     if(newValue!=""){
    //       this.create.localisation = ""
    //       let options = {ref_inscription:newValue}
    //       this.getSalleByInscription(options)
    //     }
    //   },
    //   deep: true
    // },
    // 'create.type': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue type",newValue)
    //     if(newValue!="" && this.create.saison != ""){
    //       this.create.categorie = ""
    //       let options = {saison:this.create.saison,type:newValue}
    //       this.getCategorieByTypeInscription(options)
    //     }
    //   },
    //   deep: true
    // }

    // 'create.type': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue type",newValue)
    //     if(this.vue=='creer' && this.create.saison!="" && newValue!=null){
    //       this.create.categorie=""
    //       let options = {ref_inscription:this.create.saison,type:this.create.type}
    //       this.getCategorieByTypeInscription(options)
    //     }
    //   },
    //   deep: true
    // }
    "create.type": {
      handler(newValue, oldValue) {
        // console.log("newValue type", newValue);
        if (this.vue == "creer" && newValue) {
          this.create.categorie = "";
          this.controlSave();
        }
      },
      deep: true,
    },
    "create.saison": {
      handler(newValue, oldValue) {
        // console.log("newValue type", newValue);
        if (this.vue == "creer" && newValue) {
          this.create.categorie = "";
          this.controlSave();
        }
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.horaire = [];
      Object.assign(this.$data.create, this.$options.data().create);
      Object.assign(this.$data.update, this.$options.data().update);
      Object.assign(this.$data.voir, this.$options.data().voir);
      Object.assign(this.$data.add, this.$options.data().add);
      Object.assign(this.$data.trash, this.$options.data().trash);
      this.itemUpdate = {};
      this.selected = [];

      // this.horaireFIlter = []
      // this.voir.saison = ""
      // this.voir.type = null
      // this.add.save = false
      // this.update.save = false
      // this.create.save = false
    },
    async getSalleByInscription(options) {
      let arraySalle = await Salles.getByInscription(options);
      arraySalle.forEach((el) => {
        this.salle.push({ text: el.nom, value: el.ref });
      });
    },

    getInscriptionByID(id) {
      let findEl = this.arrayInscription.find((el) => el.id == id);
      // console.log("findEl", findEl);
      return findEl.ref;
    },

    assignUpdate(item) {
      // console.log("item", item);
      if (this.vue == "modifier") {
        let options = {
          type: item.type,
          ref_inscription: item.ref_inscription,
          // saison: this.getInscriptionByID(item.id_inscription)
        };
        this.getCategorieByTypeInscription(options);
        this.getSalleByInscription(options);

        // console.log(
        //   "saison ref ",
        //   this.getInscriptionByID(item.id_inscription)
        // );
        // this.update.saison=this.getInscriptionByID(item.id_inscription)
        this.update.saison = item.ref_inscription;
        this.update.ref = item.ref;
        this.update.type = item.type;
        this.update.categorie = item.categorie_ref;
        this.update.description = item.description;
        this.update.localisation = item.ref_location;
        this.update.adresse = item.adresse;
        this.update.switchReadOnly = item.readonly;

        this.add.ref = item.ref;
      }

      if (this.vue == "modifier-2") {
        this.update.ref = item.ref;
        this.update.creneau[0].jour = item.jour;
        this.update.creneau[0].time = item.creneau.split("/")[0];
        this.update.creneau[0].time2 = item.creneau.split("/")[1];
        this.update.creneau[0].description2 = item.description;
        this.update.creneau[0].quota = item.quotas;
      }
    },

    controlSelect() {
      if (
        this.selected.length &&
        (this.vue == "voir" || this.vue == "modifier")
      ) {
        this.trash.save = true;
      } else {
        this.trash.save = false;
      }
    },

    async supprimer() {
      // console.log("this.selected", this.selected);
      if (this.trash.save && this.vue == "voir") {
        let deleteSelected = await Horaires.delete({
          array_ref: this.selected,
        });
        // console.log("deleteSelected save", deleteSelected);
        this.trash.save = !deleteSelected;
        this.selected = [];
        let options = {
          ref_inscription: this.voir.saison,
          type: this.voir.type,
        };
        this.getHoraire(options);
      }

      if (this.trash.save && this.vue == "modifier") {
        let deleteSelected = await Horaire_days.delete({
          array_ref: this.selected,
        });
        // console.log("deleteSelected save", deleteSelected);
        this.trash.save = !deleteSelected;
        this.itemUpdate.creneau = JSON.parse(
          JSON.stringify(this.itemUpdate.creneau)
        ).filter((item) => !this.selected.includes(item.ref));
        this.selected = [];
      }
    },

    async getHoraire(options) {
      let arrayHoraire = await Horaires.getBySaison(options);
      // console.log("arrayHoraires.data", arrayHoraire);
      this.horaire = arrayHoraire;
      // this.horaireFilter = arrayHoraire
    },

    async getCategorieByTypeInscription(options) {
      // this.create.categorie=""
      let arrayCategorie = await Categories.getByTypeAndIscription(options);
      // console.log("arrayInscription.data", arrayCategorie);
      this.categorie = [];
      arrayCategorie.forEach((element) => {
        this.categorie.push({
          text: `${this.capitalization(element.niveau)} (${element.startAge}${
            element.endAge ? "/" + element.endAge + " ans" : ""
          })`,
          value: element.ref,
        });
      });
    },

    capitalization(string) {
      return string[0].toUpperCase() + string.slice(1);
    },
    async getSaison() {
      this.arrayInscription = await Inscriptions.get();
      // console.log("arrayInscription.data", this.arrayInscription);
      this.arrayInscription.forEach((element) => {
        this.saison.push({ text: element.saison, value: element.ref });
      });
    },
    async save() {
      // console.log("save enter");
      if (this.vue == "modifier-2" && this.update.save) {
        // console.log("save 4", this.update);
        let putHoraireDay = await Horaire_days.put({
          description: this.update.creneau[0].description2,
          creneau:
            this.update.creneau[0].time + "/" + this.update.creneau[0].time2,
          jour: this.update.creneau[0].jour,
          ref: this.update.ref,
          quotas: this.update.creneau[0].quota,
        });
        // console.log("putHoraireDay save", putHoraireDay);
        this.update.save = !putHoraireDay;
      }

      if (this.vue == "modifier" && this.update.save) {
        // console.log("save 1", this.update);
        let putHoraire = await Horaires.put({
          location: this.update.localisation,
          // adresse: this.update.adresse,
          // description: this.update.description,
          readonly: this.update.switchReadOnly,
          // type: this.update.type,
          ref: this.update.ref,
          categorie: this.update.categorie,
        });
        // console.log("putHoraire save", putHoraire);
        this.update.save = !putHoraire;
        // console.log("this.update.save", this.update.save);
      }

      if (this.vue == "creer" && this.create.save) {
        // console.log("save 2");
        let postHoraire = await Horaires.post({
          location: this.create.localisation,
          // adresse: this.create.adresse,
          // description: this.create.description,
          readonly: this.create.switchReadOnly,
          type: this.create.type,
          ref: this.create.saison,
          categorie: this.create.categorie,

          creneau: this.create.creneau,
          // quotas:this.create.quota,
          // description_day:this.create.description2,
          // readonly_day:this.create.switchReadOnly,
          // creneau:this.create.time+"/"+this.create.time2,
          // jour:this.create.jour,
        });
        // this.create.save = !postHoraire
        if (postHoraire) {
          //  this.create.saison=""
          //  this.create.type=null
          //  this.create.categorie=""
          //  this.create.description=""
          //  this.create.localisation=""
          //  this.create.adresse=""
          //  this.create.switchReadOnly=null

          //  this.create.creneau[0].jour=""
          //  this.create.creneau[0].time2=null
          //  this.create.creneau[0].time=null
          //  this.create.creneau[0].description2=""
          //  this.create.creneau[0].quota=""

          //  this.create.creneau[1].jour=""
          //  this.create.creneau[1].time2=null
          //  this.create.creneau[1].time=null
          //  this.create.creneau[1].description2=""
          //  this.create.creneau[1].quota=""

          //  this.create.creneau[2].jour=""
          //  this.create.creneau[2].time2=null
          //  this.create.creneau[2].time=null
          //  this.create.creneau[2].description2=""
          //  this.create.creneau[2].quota=""

          //  this.create.creneau[3].jour=""
          //  this.create.creneau[3].time2=null
          //  this.create.creneau[3].time=null
          //  this.create.creneau[3].description2=""
          //  this.create.creneau[3].quota=""
          Object.assign(this.$data.create, this.$options.data().create);
        }
      }

      if (this.vue == "ajouter" && this.add.save) {
        // console.log("save 3");
        let postHoraireDay = await Horaire_days.post({
          ref: this.add.ref,
          creneau: this.add.creneau,
          // quotas:this.create.quota,
          // description_day:this.create.description2,
          // readonly_day:this.create.switchReadOnly,
          // creneau:this.create.time+"/"+this.create.time2,
          // jour:this.create.jour,
        });
        // this.add.save = !postHoraireDay
        if (postHoraireDay) {
          Object.assign(this.$data.add, this.$options.data().add);
        }
        // if(postHoraire){
        // this.create.saisonStart=""
        // this.create.saisonEnd=""
        // this.create.yearInscription=""
        // this.create.date=null
        // this.create.date2=null
        // this.create.switchCreateActivate=null
        // }
      }
    },
    controlVoir() {
      // console.log("test voir");
      if (this.vue == "voir" && this.voir.saison != "") {
        this.trash.save = false;
        this.selected = [];
        let options = {
          ref_inscription: this.voir.saison,
          type: this.voir.type,
        };
        this.getHoraire(options);
      }
    },
    controlSave() {
      // console.log("test");
      // console.log("$refs", this.$refs);
      if (
        (this.vue == "ajouter" &&
          this.add.creneau[0].jour != "" &&
          this.add.creneau[0].time2 != null &&
          this.add.creneau[0].time != null) ||
        // || this.add.creneau[0].description2!=""
        // || this.add.creneau[0].quota!=""

        (this.add.creneau[1].jour != "" &&
          this.add.creneau[1].time2 != null &&
          this.add.creneau[1].time != null) ||
        // || this.add.creneau[1].description2!=""
        // || this.add.creneau[1].quota!=""

        (this.add.creneau[2].jour != "" &&
          this.add.creneau[2].time2 != null &&
          this.add.creneau[2].time != null) ||
        // || this.add.creneau[2].description2!=""
        // || this.add.creneau[2].quota!=""

        (this.add.creneau[3].jour != "" &&
          this.add.creneau[3].time2 != null &&
          this.add.creneau[3].time != null)
        // || this.add.creneau[3].description2!=""
        // || this.add.creneau[3].quota!=""
      ) {
        // console.log("this.saisonStart 1", this.create);
        this.add.save = true;
      } else {
        // console.log("this.saisonStart 2", this.create);
        this.add.save = false;
      }

      if (this.vue == "creer" && this.create.saison != "") {
        let options = {
          ref_inscription: this.create.saison,
          type: this.create.type,
        };
        if (this.create.type != null) {
          // this.create.categorie=""
          this.getCategorieByTypeInscription(options);
        }
        this.getSalleByInscription(options);
      }

      if (
        (this.vue == "creer" &&
          // && this.$data.create!=this.$options.data().create
          this.create.saison != "" &&
          this.create.type != null &&
          this.create.categorie != "" &&
          this.create.localisation != "" &&
          // || this.create.switchReadOnly!=false

          this.create.creneau[0].jour != "" &&
          this.create.creneau[0].time2 != null &&
          this.create.creneau[0].time != null) ||
        // || this.create.creneau[0].description2!=""
        // || this.create.creneau[0].quota!=""

        (this.create.creneau[1].jour != "" &&
          this.create.creneau[1].time2 != null &&
          this.create.creneau[1].time != null) ||
        // || this.create.creneau[1].description2!=""
        // || this.create.creneau[1].quota!=""

        (this.create.creneau[2].jour != "" &&
          this.create.creneau[2].time2 != null &&
          this.create.creneau[2].time != null) ||
        // || this.create.creneau[2].description2!=""
        // || this.create.creneau[2].quota!=""

        (this.create.creneau[3].jour != "" &&
          this.create.creneau[3].time2 != null &&
          this.create.creneau[3].time != null)
        // || this.create.creneau[3].description2!=""
        // || this.create.creneau[3].quota!=""
      ) {
        // console.log("this.saisonStart 1", this.create);
        this.create.save = true;
      } else {
        // console.log("this.saisonStart 2", this.create);
        this.create.save = false;
      }

      if (
        // Object.keys(this.itemUpdate).length>0 &&
        (this.vue == "modifier" &&
          // this.update.saison!=this.getInscriptionByID(this.itemUpdate.id_inscription)
          this.update.saison != this.itemUpdate.ref_inscription &&
          this.itemUpdate.ref_inscription != undefined) ||
        (this.update.type != this.itemUpdate.type &&
          this.itemUpdate.type != undefined) ||
        (this.update.categorie != this.itemUpdate.categorie_ref &&
          this.itemUpdate.categorie_ref != undefined) ||
        // || this.update.description!=this.itemUpdate.description
        (this.update.localisation != this.itemUpdate.ref_location &&
          this.itemUpdate.ref_location != undefined) ||
        // || this.update.adresse!=this.itemUpdate.adresse
        (this.update.switchReadOnly != this.itemUpdate.readonly &&
          this.itemUpdate.readonly != undefined)
      ) {
        // console.log("this.saisonStart 1", this.update);
        // console.log("this.saisonStart item 1", this.itemUpdate);
        this.update.save = true;
      } else {
        // console.log("this.saisonStart 2", this.update);
        this.update.save = false;
      }

      if (this.vue == "modifier-2") {
        if (
          // Object.keys(this.itemUpdate).length>0 &&
          (this.update.creneau[0].jour != this.itemUpdate.jour &&
            this.itemUpdate.jour != undefined) ||
          (this.update.creneau[0].time !=
            this.itemUpdate.creneau.split("/")[0] &&
            this.itemUpdate.creneau != undefined) ||
          (this.update.creneau[0].time2 !=
            this.itemUpdate.creneau.split("/")[1] &&
            this.itemUpdate.creneau != undefined) ||
          (this.update.creneau[0].description2 != this.itemUpdate.description &&
            this.itemUpdate.description != undefined) ||
          (this.update.creneau[0].quota != this.itemUpdate.quotas &&
            this.itemUpdate.quotas != undefined)
        ) {
          // console.log("this.saisonStart 1", this.update);
          this.update.save = true;
        } else {
          // console.log("this.saisonStart 2", this.update);
          this.update.save = false;
        }
      }
    },
    $moment(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getInscription() {
      this.arrayInscription = await Inscriptions.get();
    },
  },
};
</script>
