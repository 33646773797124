<template>
  <div class="px-5 py-5 elevation-6 rounded">
    <div class="d-flex justify-space-between">
      <div class="d-flex action">
        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="(vue = 'voir'), getCategorie(), init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-clipboard-text</v-icon>
          <span class="text-caption text-uppercase">Voir</span>
        </v-sheet>

        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="(vue = 'creer'), getSaison(), init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-pencil</v-icon>
          <span class="text-caption text-uppercase">Créer</span>
        </v-sheet>
      </div>
      <div class="d-flex save">
        <v-sheet
          :color="trash.save ? 'red' : 'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="supprimer()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-delete</v-icon>
          <span class="text-caption text-uppercase">Supprimer</span>
        </v-sheet>

        <v-sheet
          :color="create.save || update.save ? 'indigo' : 'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="save()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-content-save</v-icon>
          <span class="text-caption text-uppercase">Garder</span>
        </v-sheet>
      </div>
    </div>
    <div v-if="vue == 'voir'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlVoir()"
                  v-model="voir.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlVoir()"
                  v-model="voir.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Tous', value: null },
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table v-if="arrayCategorie.length > 0">
        <thead>
          <tr>
            <th class="primary--text">ID</th>
            <th class="primary--text">Catégorie</th>
            <th class="primary--text">Age entre</th>
            <th class="primary--text">Nombre entraînement</th>
            <th class="primary--text">Select</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in arrayCategorie"
            :key="index"
            @dblclick="
              (vue = 'modifier'), assignUpdate(item), (itemUpdate = item)
            "
          >
            <!-- <tr v-for="(item,index) in arrayCategorie" :key="index" @dblclick="vue='modifier',assignUpdate(item),itemUpdate=item,update.ref = item.ref,update.type = item.type, update.saison = {value:inscriptionData.find(el=>el.id == item.id_inscription)['ref']}"> -->
            <td>
              {{ index + 1 }}
            </td>
            <td class="text-capitalize">
              {{ item.niveau }}
            </td>
            <td>{{ item.startAge }} et {{ item.endAge }} ans</td>
            <td>
              {{ item.nbTrainingByweekMin }}
              {{
                item.nbTrainingByweekMax && item.nbTrainingByweekMin
                  ? `à ${item.nbTrainingByweekMax}`
                  : ""
              }}
              /sem
            </td>
            <td>
              <v-checkbox
                :key="item.ref + index"
                v-model="selected"
                :value="item.ref"
                @change="controlSelect()"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'creer'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
            <th class="primary--text">Catégorie</th>
            <th class="primary--text">Age entre</th>
            <th class="primary--text">Nombre entraînement</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="create.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="create.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model.trim="create.categorie"
                  :placeholder="'Categorie'"
                ></v-text-field>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="ml-0 mr-4 d-inline-block" max-width="70">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="create.startAge"
                  :placeholder="'min'"
                ></v-text-field>
              </v-responsive>
              <v-responsive class="mx-0 d-inline-block" max-width="70">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="create.endAge"
                  :placeholder="'max'"
                ></v-text-field>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="ml-0 mr-4 d-inline-block" max-width="70">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="create.nbTrainingMin"
                  :placeholder="'min'"
                ></v-text-field>
              </v-responsive>
              <v-responsive class="mx-0 d-inline-block" max-width="70">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="create.nbTrainingMax"
                  :placeholder="'max'"
                ></v-text-field>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'modifier'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
            <th class="primary--text">Catégorie</th>
            <th class="primary--text">Age entre</th>
            <th class="primary--text">Nombre entraînement</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  readonly
                  v-model="update.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  readonly
                  v-model="update.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="update.categorie"
                  :placeholder="'Categorie'"
                ></v-text-field>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="ml-0 mr-4 d-inline-block" max-width="70">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="update.startAge"
                  :placeholder="'min'"
                ></v-text-field>
              </v-responsive>
              <v-responsive class="mx-0 d-inline-block" max-width="70">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="update.endAge"
                  :placeholder="'max'"
                ></v-text-field>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="ml-0 mr-4 d-inline-block" max-width="70">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="update.nbTrainingMin"
                  :placeholder="'min'"
                ></v-text-field>
              </v-responsive>
              <v-responsive class="mx-0 d-inline-block" max-width="70">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="update.nbTrainingMax"
                  :placeholder="'max'"
                ></v-text-field>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import Inscriptions from "@/services/Inscription.js";
import Categories from "@/services/Categorie.js";
export default {
  name: "Categories",
  data: () => ({
    // Categorie
    saison: [],
    arrayCategorie: [],
    arrayType: [{ 1: "enfant" }, { 2: "adulte" }],
    inscriptionData: [],
    // Categorie

    selected: [],
    trash: {
      save: false,
    },

    itemUpdate: {},
    update: {
      save: false,
      saison: "",
      type: null,
      categorie: "",
      startAge: "",
      endAge: "",
      nbTrainingMin: "",
      nbTrainingMax: "",
      ref: null,
    },

    create: {
      save: false,
      saison: "",
      type: null,
      categorie: "",
      startAge: "",
      endAge: "",
      nbTrainingMin: "",
      nbTrainingMax: "",
    },

    voir: {
      saison: "",
      type: null,
    },

    arrayInscription: [],
    vue: "voir",
  }),
  beforeMount() {
    this.getSaison();
  },
  mounted() {
    // this.getCategorie()
  },
  methods: {
    init() {
      Object.assign(this.$data.create, this.$options.data().create);
      Object.assign(this.$data.update, this.$options.data().update);
      Object.assign(this.$data.voir, this.$options.data().voir);
      Object.assign(this.$data.trash, this.$options.data().trash);
      this.itemUpdate = {};
      this.arrayCategorie = [];
      this.selected = [];
    },
    assignUpdate(item) {
      this.update.saison = this.inscriptionData.find(
        (el) => el.id == item.id_inscription
      )["ref"];
      this.update.type = item.type;
      this.update.categorie = this.capitalization(item.niveau);
      this.update.startAge = item.startAge;
      this.update.endAge = item.endAge;
      this.update.nbTrainingMin = item.nbTrainingByweekMin;
      this.update.nbTrainingMax = item.nbTrainingByweekMax;
      this.update.ref = item.ref;
    },

    controlSelect() {
      if (this.selected.length && this.vue == "voir") {
        this.trash.save = true;
      } else {
        this.trash.save = false;
      }
    },

    async supprimer() {
      // console.log("this.selected",this.selected)
      if (this.trash.save) {
        let deleteSelected = await Categories.delete({
          array_ref: this.selected,
        });
        // console.log("deleteSelected save",deleteSelected)
        this.trash.save = !deleteSelected;
        this.selected = [];
        let options = {
          ref_inscription: this.voir.saison,
          type: this.voir.type,
        };
        this.getCategorie(options);
      }
    },

    capitalization(string) {
      return string[0].toUpperCase() + string.slice(1);
    },

    async getCategorie(options) {
      // this.arrayCategorie = await Categories.get()
      this.arrayCategorie = await Categories.getByTypeAndIscription(options);
    },

    async getSaison() {
      let arrayInscription = await Inscriptions.get();
      // console.log("arrayInscription.data",arrayInscription)
      arrayInscription.forEach((element) => {
        this.saison.push({ text: element.saison, value: element.ref });
      });
      this.inscriptionData = arrayInscription;
    },

    async save() {
      // console.log("save 1")
      if (this.vue == "modifier" && this.update.save) {
        // console.log("save 1")
        let putInscription = await Categories.put({
          niveau: this.update.categorie.toLocaleLowerCase(),
          startAge: this.update.startAge,
          endAge: this.update.endAge,
          nbTrainingByweekMin: this.update.nbTrainingMin,
          nbTrainingByweekMax: this.update.nbTrainingMax,
          type: this.update.type,
          ref: this.update.ref,
          saison: this.update.saison,
        });
        // console.log("putInscription save",putInscription)
        this.update.save = !putInscription;
      }

      if (this.vue == "creer" && this.create.save) {
        // console.log("save 2")
        let postCategorie = await Categories.post({
          niveau: this.create.categorie.toLocaleLowerCase(),
          startAge: this.create.startAge,
          endAge: this.create.endAge,
          nbTrainingByweekMin: this.create.nbTrainingMin,
          nbTrainingByweekMax: this.create.nbTrainingMax,
          type: this.create.type,
          ref: this.create.saison,
        });
        // this.create.save = !postCategorie
        if (postCategorie) {
          // this.create.saison=""
          // this.create.type=null
          // this.create.categorie=""
          // this.create.startAge=""
          // this.create.endAge=""
          // this.create.nbTrainingMin=""
          // this.create.nbTrainingMax=""
          Object.assign(this.$data.create, this.$options.data().create);
        }
      }
    },
    controlVoir() {
      // console.log("test voir")
      if (this.vue == "voir" && this.voir.saison != "") {
        this.trash.save = false;
        this.selected = [];
        let options = {
          ref_inscription: this.voir.saison,
          type: this.voir.type,
        };
        this.getCategorie(options);
      }
    },
    controlSave() {
      // console.log("test")
      if (
        this.vue == "creer" &&
        this.create.saison != "" &&
        this.create.type != null &&
        this.create.categorie != "" &&
        this.create.startAge != "" &&
        this.create.endAge != "" &&
        this.create.nbTrainingMin != "" &&
        this.create.nbTrainingMax != ""
      ) {
        // console.log("this.saisonStart 1",this.create.startAge)
        this.create.save = true;
      } else {
        // console.log("this.saisonStart 2",this.create.categorie)
        this.create.save = false;
      }

      if (
        (this.vue == "modifier" &&
          this.update.saison != "" &&
          this.update.saison !=
            this.inscriptionData.find(
              (el) => el.id == this.itemUpdate.id_inscription
            )["ref"] &&
          this.itemUpdate.id_inscription != undefined) ||
        (this.update.type != null &&
          this.update.type != this.itemUpdate.type &&
          this.itemUpdate.type != undefined) ||
        (this.update.categorie != "" &&
          this.itemUpdate.niveau != undefined &&
          this.update.categorie !=
            this.capitalization(this.itemUpdate.niveau)) ||
        (this.update.startAge != "" &&
          this.update.startAge != this.itemUpdate.startAge &&
          this.itemUpdate.startAge != undefined) ||
        (this.update.endAge != "" &&
          this.update.endAge != this.itemUpdate.endAge &&
          this.itemUpdate.endAge != undefined) ||
        (this.update.nbTrainingMin != "" &&
          this.update.nbTrainingMin != this.itemUpdate.nbTrainingByweekMin &&
          this.itemUpdate.nbTrainingByweekMin != undefined) ||
        (this.update.nbTrainingMax != "" &&
          this.update.nbTrainingMax != this.itemUpdate.nbTrainingByweekMax &&
          this.itemUpdate.nbTrainingByweekMax != undefined)
      ) {
        // console.log("apres:",this.update.saison.value, "avant:",this.inscriptionData.find(el=>el.id == this.itemUpdate.id_inscription)["ref"],"other",this.update.saison)
        this.update.save = true;
      } else {
        // console.log("save update 2")
        this.update.save = false;
      }
    },
  },
};
</script>
