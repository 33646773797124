<template>
  <div class="px-5 py-5 elevation-6 rounded">
    <div class="d-flex justify-space-between">
      <div class="d-flex action">
        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="(vue = 'voir'), init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-clipboard-text</v-icon>
          <span class="text-caption text-uppercase">Voir</span>
        </v-sheet>

        <v-sheet
          color="primary "
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="(vue = 'creer'), getSaison(), init()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-pencil</v-icon>
          <span class="text-caption text-uppercase">Créer</span>
        </v-sheet>
      </div>
      <div class="d-flex save">
        <v-sheet
          :color="trash.save ? 'red' : 'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="supprimer()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-delete</v-icon>
          <span class="text-caption text-uppercase">Supprimer</span>
        </v-sheet>

        <v-sheet
          :color="create.save || update.save ? 'indigo' : 'grey'"
          elevation="6"
          class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
          dark
          :max-width="65"
          @click="save()"
          style="cursor: pointer"
        >
          <v-icon size="32">mdi-content-save</v-icon>
          <span class="text-caption text-uppercase">Garder</span>
        </v-sheet>
      </div>
    </div>
    <div v-if="vue == 'voir'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlVoir()"
                  v-model="voir.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlVoir()"
                  v-model="voir.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table v-if="moyen.length">
        <thead>
          <tr>
            <th class="primary--text">Mode</th>
            <th class="primary--text">Description</th>
            <th class="primary--text">Select</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in moyen"
            :key="index"
            @dblclick="
              (vue = 'modifier'), assignUpdate(item), (itemUpdate = item)
            "
          >
            <td style="width: 50%">
              {{ item.mode }}
            </td>
            <td style="width: 50%">{{ item.descriptif }}</td>
            <td>
              <v-checkbox
                :key="item.ref + index"
                v-model="selected"
                :value="item.ref"
                @change="controlSelect()"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'creer'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="create.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  @change="controlSave()"
                  v-model="create.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Mode de paiement</th>
            <th class="primary--text">Description</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in create.moyen" :key="index">
            <td>
              <v-responsive class="mx-0" max-width="200">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="item.mode"
                  :placeholder="'Intitulé'"
                ></v-text-field>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-textarea
                  rows="1"
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="item.description"
                  :placeholder="'Description'"
                ></v-textarea>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-if="vue == 'modifier'">
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Saison</th>
            <th class="primary--text">Type</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  readonly
                  @change="controlSave()"
                  v-model="update.saison"
                  :placeholder="'Saison'"
                  :items="saison"
                ></v-select>
              </v-responsive>
            </td>
            <td>
              <v-responsive class="mx-0" max-width="150">
                <v-select
                  readonly
                  @change="controlSave()"
                  v-model="update.type"
                  :placeholder="'Type'"
                  :items="[
                    { text: 'Enfant', value: 1 },
                    { text: 'Adulte', value: 2 },
                  ]"
                ></v-select>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">Mode de paiement</th>
            <th class="primary--text">Description</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in update.moyen" :key="index">
            <td
              class="align-content-start d-flex"
              style="width: 50%; height: 100px"
            >
              <v-responsive class="mx-0" max-width="200">
                <v-text-field
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="item.mode"
                  :placeholder="'Intitulé'"
                ></v-text-field>
              </v-responsive>
            </td>
            <td style="width: 50%">
              <v-responsive class="mx-0" max-width="100%">
                <v-textarea
                  rows="8"
                  @change="controlSave()"
                  @keyup="controlSave()"
                  v-model="item.description"
                  :placeholder="'Description'"
                ></v-textarea>
              </v-responsive>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Inscriptions from "@/services/Inscription.js";
import Categories from "@/services/Categorie.js";
import Paiement_modalites from "@/services/Paiement_modalite.js";

export default {
  name: "Paiements",
  data: () => ({
    // Horaire
    saison: [],
    // categorie:[],
    // horaire:[],
    // horaireFilter:[],
    arrayInscription: [],
    // Horaire
    moyen: [],

    selected: [],
    trash: {
      save: false,
    },

    itemUpdate: {},

    update: {
      save: false,
      ref: null,
      saison: "",
      type: null,
      moyen: [
        {
          mode: "",
          description: "",
        },
      ],
    },

    voir: {
      saison: "",
      type: null,
    },

    create: {
      save: false,
      ref: null,
      saison: "",
      type: null,
      moyen: [
        {
          mode: "",
          description: "",
        },
        {
          mode: "",
          description: "",
        },
        {
          mode: "",
          description: "",
        },
        {
          mode: "",
          description: "",
        },
        {
          mode: "",
          description: "",
        },
      ],
    },
    arrayInscription: [],
    vue: "voir",
  }),
  mounted() {
    this.getSaison();
  },
  watch: {
    // 'voir.type': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue type voir",newValue)
    //     if(newValue!="" && this.voir.saison!=""){
    //       // let options = {ref:this.voir.saison}
    //       // this.getHoraire(options)
    //       this.cotisation = this.horaireFilter.filter(element=>element.type == newValue)
    //     }
    //   },
    //   deep: true
    // },

    // 'voir.saison': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue saison voir",newValue)
    //     if(newValue!="" && this.voir.type =null){
    //       let options = {ref:newValue}
    //       this.getHoraire(options)
    //     }
    //   },
    //   deep: true
    // },

    // 'update.saison': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue saison",newValue)
    //     if(newValue!="" && this.update.type != null){
    //       // this.update.categorie = ""
    //       let options = {type:this.update.type,saison:newValue}
    //       this.getCategorieByTypeInscription(options)
    //     }
    //   },
    //   deep: true
    // },
    // 'update.type': {
    //   handler(newValue, oldValue) {
    //     console.log("newValue type",newValue)
    //     if(newValue!="" && this.update.saison != ""){
    //       // this.update.categorie = ""
    //       let options = {saison:this.update.saison,type:newValue}
    //       this.getCategorieByTypeInscription(options)
    //     }
    //   },
    //   deep: true
    // },

    "create.saison": {
      handler(newValue, oldValue) {
        // console.log("newValue saison",newValue)
        if (newValue != "" && this.create.type != null) {
          this.create.categorie = "";
          let options = { type: this.create.type, saison: newValue };
          this.getCategorieByTypeInscription(options);
        }
      },
      deep: true,
    },
    "create.type": {
      handler(newValue, oldValue) {
        // console.log("newValue type", newValue);
        if (newValue != "" && this.create.saison != "") {
          this.create.categorie = "";
          let options = { saison: this.create.saison, type: newValue };
          this.getCategorieByTypeInscription(options);
        }
      },
      deep: true,
    },
  },
  methods: {
    init() {
      Object.assign(this.$data.create, this.$options.data().create);
      Object.assign(this.$data.update, this.$options.data().update);
      Object.assign(this.$data.voir, this.$options.data().voir);
      Object.assign(this.$data.trash, this.$options.data().trash);
      this.itemUpdate = {};
      this.moyen = [];
      this.selected = [];
    },
    getInscriptionByID(id) {
      let findEl = this.arrayInscription.find((el) => el.id == id);
      // console.log("findEl", findEl);
      return findEl.ref;
    },

    assignUpdate(item) {
      // console.log("item", item);
      if (this.vue == "modifier") {
        this.update.saison = this.getInscriptionByID(item.id_inscription);
        this.update.ref = item.ref;
        this.update.type = item.type;
        this.update.moyen[0].mode = item.mode;
        this.update.moyen[0].description = item.descriptif;
      }
    },

    controlSelect() {
      if (this.selected.length && this.vue == "voir") {
        this.trash.save = true;
      } else {
        this.trash.save = false;
      }
    },

    async supprimer() {
      // console.log("this.selected", this.selected);
      if (this.trash.save) {
        let deleteSelected = await Paiement_modalites.delete({
          array_ref: this.selected,
        });
        // console.log("deleteSelected save", deleteSelected);
        this.trash.save = !deleteSelected;
        this.selected = [];
        let options = {
          ref_inscription: this.voir.saison,
          type: this.voir.type,
        };
        this.getPaiementModalite(options);
      }
    },

    async getPaiementModalite(options) {
      let arrayPaiementModalite = await Paiement_modalites.getBySaisonType(
        options
      );
      // console.log("arrayPaiementModalite.data", arrayPaiementModalite);
      this.moyen = arrayPaiementModalite;
    },

    async getCategorieByTypeInscription(options) {
      let arrayCategorie = await Categories.getByTypeAndIscription(options);
      // console.log("arrayInscription.data", arrayCategorie);
      this.categorie = [];
      arrayCategorie.forEach((element) => {
        this.categorie.push({
          text: `${this.capitalization(element.niveau)} (${element.startAge}${
            element.endAge ? "/" + element.endAge + " ans" : ""
          })`,
          value: element.ref,
        });
      });
    },

    capitalization(string) {
      return string[0].toUpperCase() + string.slice(1);
    },
    async getSaison() {
      this.arrayInscription = await Inscriptions.get();
      // console.log("arrayInscription.data", this.arrayInscription);
      this.arrayInscription.forEach((element) => {
        this.saison.push({ text: element.saison, value: element.ref });
      });
    },
    async save() {
      if (this.vue == "modifier" && this.update.save) {
        // console.log("save 1", this.update);
        let putPaiement = await Paiement_modalites.put({
          descriptif: this.update.moyen[0].description,
          mode: this.update.moyen[0].mode,
          ref: this.update.ref,
        });
        // console.log("putHoraire save", putPaiement);
        this.update.save = !putPaiement;
      }

      if (this.vue == "creer" && this.create.save) {
        // console.log("save 2");
        let postPaiement = await Paiement_modalites.post({
          ref_inscription: this.create.saison,
          type: this.create.type,
          moyen: this.create.moyen,
        });
        // this.create.save = !postPaiement
        if (postPaiement) {
          //  this.create.saison=""
          //  this.create.type=null

          //  this.create.moyen[0].mode=""
          //  this.create.moyen[0].description=""

          //  this.create.moyen[1].mode=""
          //  this.create.moyen[1].description=""

          //  this.create.moyen[2].mode=""
          //  this.create.moyen[2].description=""

          //  this.create.moyen[3].mode=""
          //  this.create.moyen[3].description=""

          //  this.create.moyen[4].mode=""
          //  this.create.moyen[4].description=""
          Object.assign(this.$data.create, this.$options.data().create);
        }
      }
    },
    controlVoir() {
      if (this.vue == "voir") {
        if (this.voir.saison != "" && this.voir.type != null) {
          let options = {
            ref_inscription: this.voir.saison,
            type: this.voir.type,
          };
          this.getPaiementModalite(options);
        }
      }
    },
    controlSave() {
      // console.log("test");
      // console.log("$refs", this.$refs);

      if (
        (this.vue == "creer" &&
          this.create.saison != "" &&
          this.create.type != null &&
          this.create.moyen[0].mode != "" &&
          this.create.moyen[0].description != "") ||
        (this.create.moyen[1].mode != "" &&
          this.create.moyen[1].description != "") ||
        (this.create.moyen[2].mode != "" &&
          this.create.moyen[2].description != "") ||
        (this.create.moyen[3].mode != "" &&
          this.create.moyen[3].description != "") ||
        (this.create.moyen[4].mode != "" &&
          this.create.moyen[4].description != "")
      ) {
        // console.log("this.saisonStart 1", this.create);
        this.create.save = true;
      } else {
        // console.log("this.saisonStart 2", this.create);
        this.create.save = false;
      }

      if (
        (this.vue == "modifier" &&
          this.update.moyen[0].mode != this.itemUpdate.mode &&
          this.itemUpdate.mode != undefined &&
          this.update.moyen[0].mode != "") ||
        (this.update.moyen[0].description != this.itemUpdate.descriptif &&
          this.itemUpdate.descriptif != undefined &&
          this.update.moyen[0].description != "")
      ) {
        // console.log("this.saisonStart 1", this.update);
        this.update.save = true;
      } else {
        // console.log("this.saisonStart 2", this.update);
        this.update.save = false;
      }
    },
    $moment(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getInscription() {
      this.arrayInscription = await Inscriptions.get();
    },
  },
};
</script>
