// import axios from "axios"
import Api from "../../config/config.js";

export default {
  async post(options) {
    try {
      let postTarif = await Api.instance.post(`/tarif/generate`, options);
      // console.log("postTarif",postTarif)
      return postTarif.data.create;
    } catch (error) {
      console.log(error);
    }
  },
  async getBySaisonTypeCategorie(options) {
    try {
      let getTarif = await Api.instance.get(
        `/tarif/get-by-saison-type-categorie`,
        { params: options }
      );
      // console.log("getTarif",getTarif)
      return getTarif.data;
    } catch (error) {
      console.log(error);
    }
  },
  async put(options) {
    try {
      let updateTarif = await Api.instance.put(`/tarif/update`, options);
      // console.log("postTarif",updateTarif)
      return updateTarif.data.update;
    } catch (error) {
      console.log(error);
    }
  },
  async delete(options) {
    try {
      let deleteTarif = await Api.instance.post(`/tarif/delete`, options);
      // console.log("deleteTarif",deleteTarif)
      return deleteTarif.data.delete;
    } catch (error) {
      console.log(error);
    }
  },
};
